.modal-wrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 50;
  background-color: black;
  cursor: pointer;
  overflow-y: scroll;
  .motion-wrapper {
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem; /* 32px */
    margin-bottom: 2rem; /* 32px */
    padding-left: 2rem;
    padding-right: 2rem;
    cursor: default;
    .motion-div-1 {
      img {
        max-height: 100%;
      }
    }
    .motion-div-2 {
      background-color: white;
      padding: 1rem;
      h3 {
        font-size: 1.5rem; /* 24px */
        line-height: 2rem; /* 32px */
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
      .badge {
        background-color: gray;
        border: none;
        color: white;
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        padding: 0.25rem;
        width: fit-content;
      }
      p {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
