@import "base/variables";
@import "base/reset";
@import "base/fonts";
@import "components/width";
@import "components/list";
@import "components/card";
@import "components/modal";
@import "pages/landing";
@import "components/screenwrapper";
@import "components/navbar";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body
{
	overflow: hidden;
	font-family:  'Roboto', sans-serif;
	font-weight:normal;
	font-style:normal;
	font-size: 1em;
	color: #009900;
}