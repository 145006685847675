$font: "Martian Mono", monospace;
$font2: "Alumni Sans Pinstripe", sans-serif;

$bg: white;
$black: #120408;
$white: #fff;
$grayBg: #ecebeb;
$lightGrayBg: #f9f9f9;
$border: #ccc;
$green: #2bff88;
$blue: #2e7cf6;

$footerGray: #948e90;

$textDarkGray: #120408;
$textGray: #8e8e8e;
$textLightGray: #a6a5a5;

$fadeOff: left 0s 0.15s, opacity 0.15s;
$fadeOn: left 0s, opacity 0.15s;
$fadeOffR: right 0s 0.15s, opacity 0.15s;
$fadeOnR: right 0s, opacity 0.15s;
