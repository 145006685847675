.navbar {
    width: 100vw;
    height: 50px;
    background-color: transparent;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    ul{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        li{
            .homeLink{
                .home{
                    color: green;
                }
            }
            .blogLink{
                .blog{
                    color: green;
                }
            }
        }
    }
}