

.crt{
    overflow: hidden;
    height: 100%;
    width:100%;
	animation: textShadow 10s infinite;
    &::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(
            to bottom,
            rgba(18, 16, 16, 0) 50%,
            rgba(0, 0, 0, 0.25) 50%
        );
        background-size: 100% 8px;
        z-index: 2;
        pointer-events: none;
    }
}

.scanline {
    width: 100%;
    height: 100px;
    z-index: 8;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(255, 255, 255, 0.103) 10%,
        rgba(0, 0, 0, 0.1) 100%
    );
    opacity: 0.5;
    position: absolute;
    bottom: 100%;
    animation: scanline 8s linear infinite;
	
    @keyframes scanline{
        0% {
            bottom: 100%;
        }
        80% {
            bottom: 100%;
        }
        100% {
            bottom: -10%;
        }
    }
}

@keyframes textShadow {
	0% {
		text-shadow: 0 0 0 green
	}
	5% {
		text-shadow: 0 0 0 green
	}
	10% {
		text-shadow: 0 0 0 green
	}
	15% {
		text-shadow: 0 0 0 green
	}
	20% {
		text-shadow: 0 0 0 green
	}
	25% {
		text-shadow: 0 0 0 green
	}
	30% {
		text-shadow: 0 0 0 green
	}
	35% {
		text-shadow: 0 0 0 green
	}
	40% {
		text-shadow: 0 0 0 green
	}
	45% {
		text-shadow: 0 0 0 green
	}
	50% {
		text-shadow: 0 0 0 green
	}
	55% {
		text-shadow: 0 0 0 green
	}
	60% {
		text-shadow: 0 0 0 green
	}
	65% {
		text-shadow: 0 0 0 green
	}
	70% {
		text-shadow: 0 0 0 green
	}
	75% {
		text-shadow: 0 0 0 green
	}
	80% {
		text-shadow: 0 0 0 green
	}
	85% {
		text-shadow: 0 0 0 green
	}
	90% {
		text-shadow: 0 0 0 green
	}
	95% {
		text-shadow: 0 0 0 green
	}
	99% {
		text-shadow: 0 0 0 green
	}
	100% {
		text-shadow: 5px -5px 10px green,
			-5px 5px 3px green;
	}
}


  @import url("https://fonts.googleapis.com/css?family=VT323&display=swap");

  .terminal {
	  font-family:  'Roboto', sans-serif;
      text-transform: uppercase;
  }

  .frame {
    min-width: 90%;
	min-height: 95%;
	background-color: black;
	background-image: radial-gradient(200% 250% at center, rgba(0, 50, 50, 0.75), black 100%);
	background-clip: padding-box;
	color: #009900;
	overflow: auto;

	font-family:  'Roboto', sans-serif;
	font-size: 24px;


    &::after {
        content: " ";
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-size: 100% 3px, 2px 100%;
    
        z-index: 2;
        pointer-events: none;
        }
    
    &::selection {
        color: #00f0f0;
        background: #008008;
        text-shadow: none;
    }
    &pre {
        margin: 0;
    }
    &h1 {
        font-family:  'Roboto', sans-serif;
        font-weight:normal;
        font-style:normal;
    }
}