.card-wrapper {
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
  .motion-image {
    width: 100%;
    background-color: white;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
      0 8px 10px -6px rgb(0 0 0 / 0.1);
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .badge-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    .badge {
      font: $font2;
      font-size: small;
      border: none;
      background-color: gray;
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      color: white;
    }
  }
}
