.landing-body {
  display: flex;
  width: 1000px;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .landing-left {
    h1 {
      font-size: 100px;
    }
  }

  img {
    mix-blend-mode: lighten;
    filter: sepia(100%) hue-rotate(80deg) saturate(300%);
    animation: img 1.5s infinite; /* Adjust the duration and repetition to fit your needs */
    
    // @keyframes img {
    //   0% {
    //     filter: drop-shadow(2px 4px 6px rgba(0,30,255,0.5)) drop-shadow(-2px -4px 6px rgba(255,0,80,0.3)) grayscale(100%);;
    //   }
    //   5% {
    //     filter: drop-shadow(3px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-3px 0 2px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   10% {
    //     filter: drop-shadow(1px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-1px 0 1px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   15% {
    //     filter: drop-shadow(0.4px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-0.4px 0 1px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   20% {
    //     filter: drop-shadow(3.5px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-3.5px 0 1px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   25% {
    //     filter: drop-shadow(2px 4px 6px rgba(0,30,255,0.5)) drop-shadow(-2px -4px 6px rgba(255,0,80,0.3)) grayscale(100%);;
    //   }
    //   30% {
    //     filter: drop-shadow(2px 4px 6px rgba(0,30,255,0.5)) drop-shadow(-2px -4px 6px rgba(255,0,80,0.3)) grayscale(100%);;
    //   }
    //   35% {
    //     filter: drop-shadow(3px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-3px 0 2px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   40% {
    //     filter: drop-shadow(1px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-1px 0 1px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   45% {
    //     filter: drop-shadow(0.4px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-0.4px 0 1px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   50% {
    //     filter: drop-shadow(3.5px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-3.5px 0 1px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   55% {
    //     filter: drop-shadow(2px 4px 6px rgba(0,30,255,0.5)) drop-shadow(-2px -4px 6px rgba(255,0,80,0.3)) grayscale(100%);;
    //   }
    //   60% {
    //     filter: drop-shadow(2px 4px 6px rgba(0,30,255,0.5)) drop-shadow(-2px -4px 6px rgba(255,0,80,0.3)) grayscale(100%);;
    //   }
    //   65% {
    //     filter: drop-shadow(3px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-3px 0 2px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   70% {
    //     filter: drop-shadow(1px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-1px 0 1px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   75% {
    //     filter: drop-shadow(0.4px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-0.4px 0 1px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   80% {
    //     filter: drop-shadow(3.5px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-3.5px 0 1px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);;
    //   }
    //   85% {
    //     filter: drop-shadow(2px 4px 6px rgba(0,30,255,0.5)) drop-shadow(-2px -4px 6px rgba(255,0,80,0.3)) grayscale(100%);;
    //   }
    //   90% {
    //     filter: drop-shadow(2px 4px 6px rgba(0,30,255,0.5)) drop-shadow(-2px -4px 6px rgba(255,0,80,0.3)) grayscale(100%);;
    //   }
    //   95% {
    //     filter: drop-shadow(3px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-3px 0 2px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);
    //   }
    //   100% {
    //     filter: drop-shadow(1px 0 1px rgba(0, 30, 255, 0.5)) drop-shadow(-1px 0 1px rgba(255, 0, 80, 0.3)) drop-shadow(0 0 3px rgba(0,0,0,0.5)) grayscale(100%);
    //   }
    // }
  }

}
