@font-face {
  font-family: "Martian Mono", monospace;
  src: url("https://fonts.googleapis.com/css2?family=Martian+Mono&display=swap");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Alumni Sans Pinstripe", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Alumni+Sans+Pinstripe&display=swap");
}
