.list-wrapper {
  padding: 1rem;

  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 2.25rem; /* 36px */
    line-height: 2.5rem; /* 40px */
    margin-bottom: 2rem;
  }
  .album {
    columns: 1;
    gap: 1rem;
    @media (min-width: 768px) {
      columns: 3;
    }
    @media (min-width: 1280px) {
      columns: 4;
    }
  }
}
